import React from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import NotFound from "./NotFound";
import Loader from "../assets_components/loader";

const LandingPage = React.lazy(() => import("./Landing"));
const PensionPage = React.lazy(() => import("./Pensions"));
const WelfarePage = React.lazy(() => import("./Welfare"));
const LoanPage = React.lazy(() => import("./Loan"));
const AboutPage = React.lazy(() => import("./AboutUs"));
const ContactPage = React.lazy(() => import("./ContactUs"));
const PrivacyPage = React.lazy(() => import("./Privacy"));
const SecurityPage = React.lazy(() => import("./Security"));
const TermsPage = React.lazy(() => import("./Terms"));
const FaqPage = React.lazy(() => import("./Faqs"));
const GetStartedPage = React.lazy(() => import("./GetStarted"));
const CalculatorPage = React.lazy(() => import("./Calculators"));
const ComingSoonPage = React.lazy(() => import("./CominSoon"));
const HowToUseCode = React.lazy(() => import("./HowToUse"));
const VerifyCardPage = React.lazy(() => import("./VerifyCard"));

const App = () => {
  return (
    <Router>
      <React.Suspense fallback={<Loader />}>
        <Routes>
          <Route exact path="/" element={<LandingPage />}></Route>
          <Route exact path="/pension" element={<PensionPage />}></Route>
          <Route exact path="/welfare" element={<WelfarePage />}></Route>
          <Route exact path="/loan" element={<LoanPage />}></Route>
          <Route exact path="/about" element={<AboutPage />}></Route>
          <Route exact path="/contact" element={<ContactPage />}></Route>
          <Route exact path="/privacy" element={<PrivacyPage />}></Route>
          <Route exact path="/security" element={<SecurityPage />}></Route>
          <Route exact path="/terms" element={<TermsPage />}></Route>
          <Route exact path="/faqs" element={<FaqPage />}></Route>
          <Route exact path="/getStarted" element={<GetStartedPage />}></Route>
          <Route exact path="/calculators" element={<CalculatorPage />}></Route>
          <Route exact path="/coming-soon" element={<ComingSoonPage />}></Route>
          <Route exact path="/how-to-use-code" element={<HowToUseCode />}></Route>
          <Route exact path="/verify-card" element={<VerifyCardPage/>}></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Suspense>
    </Router>
  );
};

export default App;
