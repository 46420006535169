import { Disclosure } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import { Popover } from "@headlessui/react";

const navigation = [
  { name: "Pension", href: "/pension", current: false },
  { name: "Welfare", href: "/welfare", current: false },
  { name: "Loan", href: "/loan", current: false },
  // { name: "Bulk Plan", href: "/bulk-plan", current: false },
];

const mobile_navigation = [
  { name: "Pension", href: "#/pension", current: false },
  { name: "Welfare", href: "#/welfare", current: false },
  { name: "Loan", href: "#/loan", current: false },
  // { name: "Bulk Plan", href: "#/bulk-plan", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {
  return (
    <Disclosure as="nav" className="bg-white mobile-nav">
      {({ open }) => (
        <>
          <div className="mt-3 max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 h-24">
            <div className="relative grid lg:grid-cols-4 md:grid-cols-3 items-center justify-between h-16">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center mt-4 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XIcon className="block h-9 w-9" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-12 w-12" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

              <div className="justify-center flex-shrink-0 flex sm:justify-start">
                <h6 className="uppercase font-semibold text-black flex items-center justify-center md:justify-start">
                  <Link to={"/"} spy={true} smooth={true}>
                    <img alt="" className="w-40 p-4" src={Logo} />
                  </Link>
                </h6>
              </div>
              <div className="col-span-2 flex-1 flex items-center sm:items-stretch z-1 nav-items-margin">
                <div className="hidden sm:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <Link
                        to={item.href}
                        spy={true}
                        smooth={true}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-default-blue cursor-pointer",
                          "px-3 py-2 rounded-md text-lg font-normal"
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                    <Popover className="relative px-3 py-2 rounded-md font-normal text-lg text-default-blue learn-display focus:outline-none">
                      <Popover.Button className="learn-display">
                        Learn{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          className="menu-drop-icon"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      </Popover.Button>

                      <Popover.Panel className="absolute z-10 mt-4">
                        <div className="grid grid-cols-1 menu-bar">
                          <Link
                            to={"/about"}
                            spy={true}
                            smooth={true}
                            className="mb-6"
                          >
                            <h1 className="text-lg text-default-blue cursor-pointer">
                              About Us
                            </h1>
                          </Link>
                          <Link
                            to={"/contact"}
                            spy={true}
                            smooth={true}
                            className="mb-6"
                          >
                            <h1 className="text-lg text-default-blue cursor-pointer">
                              Contact Us
                            </h1>
                          </Link>
                          <Link
                            to={"/how-to-use-code"}
                            spy={true}
                            smooth={true}
                            className="mb-6"
                          >
                            <h1 className="text-lg text-default-blue cursor-pointer">
                              How to use *434#
                            </h1>
                          </Link>
                        </div>
                      </Popover.Panel>
                    </Popover>
                  </div>
                </div>
              </div>
            
              <div className="hidden lg:block lg:w-auto">
                <a href="https://www.agent.oneplan.finance" target="blank" spy={true} smooth={true}
                  className={"nav-agent-button button-margin-left"}
                >
                  <span className='nav-agent-button-text'>Become an agent</span>
                </a>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden mobile-nav-bg">
            <div className="px-2 pt-2 pb-3">
              <Disclosure.Button className="inline-flex place-x mt-8 items-center justify-right p-2 rounded-md text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                <span className="sr-only">Open main menu</span>
                {open ? (
                  <XIcon
                    className="block h-12 w-12 text-white"
                    aria-hidden="true"
                  />
                ) : (
                  <MenuIcon
                    className="block h-12 w-12 text-white"
                    aria-hidden="true"
                  />
                )}
              </Disclosure.Button>
              <div className="mt-24"></div>
              <Link
                to={"/"}
                spy={true}
                smooth={true}
                className={"block px-3 py-2 mobile-nav-text"}
              >
                Home
              </Link>

              {mobile_navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className="block mobile-nav-text px-3 py-2"
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
              <Popover className="relative px-3 rounded-md font-normal text-default-blue learn-display">
                <Popover.Button className="learn-display mobile-nav-text">
                  Learn{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="mobile-menu-drop-icon"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </Popover.Button>

                <Popover.Panel className="mt-4">
                  <div className="grid grid-cols-1 mobile-menu-bar">
                    <Link
                      to={"/about"}
                      spy={true}
                      smooth={true}
                      className={"mb-6 mobile-dropdown-text"}
                    >
                      About Us
                    </Link>
                    <Link
                      to={"/contact"}
                      spy={true}
                      smooth={true}
                      className="mb-6 mobile-dropdown-text"
                    >
                      Contact Us
                    </Link>
                    <Link
                      to={"/how-to-use-code"}
                      spy={true}
                      smooth={true}
                      className="mobile-dropdown-text"
                    >
                      How to use *434#
                    </Link>
                    
                  </div>
                </Popover.Panel>
              </Popover>
              <a href="https://www.agent.oneplan.finance" spy={true} smooth={true}
                className={"block mb-6 px-3 mobile-nav-text"}
                >
                Become an agent
              </a>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
